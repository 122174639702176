@use '_modules' as *;

#hero .promo {
  grid-row: 1 / -1;
  height: 100%;
  z-index: 1;
  position: relative;
  text-align: center;
  padding: 0;
  overflow-x: clip;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    padding: $spacer $gap;
    @include break-out(900) {
      padding: $gap;
    }
    & > svg {
      height: 60px;
      width: 60px;
      margin: 0 auto $gap;
      color: $red;
    }
  }

  &:last-of-type {
    grid-column: 1;
    grid-row: 2;
    overflow: visible;
    picture,
    background-video {
      left: 0;
    }
  }

  picture,
  background-video {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    min-width: 50vw;
    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include break-out(768) {
    grid-row: 1;
    grid-column: 1;
    overflow-x: visible;
    min-height: 550px;

    .text {
      h2 {
        font-size: 48px;
        padding: 0 $gap;
      }
    }

    &.right {
      grid-column: 2;
      grid-row: 1;
    }
  }

  @include break-out(1200) {
    .content {
      margin: 0 auto;
      padding: $spacer;
      max-width: 550px;
    }
  }

  picture {
    &:before {
      content: '';
      top: 0;
      right: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 10;
    }
  }

  &.blue {
    picture {
      &:before {
        background-color: rgba($blue2, 0.8);
      }
    }
  }
  &.black {
    picture {
      &:before {
        background-color: rgba(black, 0.6);
      }
    }
  }
  &.red {
    picture {
      &:before {
        background-color: rgba($red, 0.8);
      }
    }
  }
  &.none {
    header {
      span {
        background-color: #76bb28;
      }
    }
  }

  tag-line {
    letter-spacing: 3px;
    font-size: 30px;
    flex-wrap: wrap;
    span {
      img {
        margin-right: $gap;
      }
    }
    @include break-out(1120) {
      font-size: 24px;
      span {
        img {
          margin-right: $gap0;
          height: 24px;
          width: 24px;
        }
      }
    }

    @include break-out(1500) {
      font-size: 30px;
      span {
        img {
          margin-right: $gap;
          height: 35px;
          width: 35px;
        }
      }
    }
  }

  header {
    --promo-banner-scale: 0.7;
    position: absolute;
    top: 0;
    right: -250px;
    transform: translate(-40px, 40px) rotate(45deg) scale(var(--promo-banner-scale, 1));
    transform-origin: 50% 0%;
    width: 500px;
    h2 {
      text-align: center;
      color: white;
      text-transform: uppercase;
      span {
        display: flex;
        flex-direction: column;
        background-color: $blue2;
        @include font-lato();
        font-size: 0.5em;
        padding: 0.4em 0;
        strong {
          @include font-lato(bold);
        }
      }
      em {
        display: block;
        font-style: normal;
        background-color: $red;
        font-size: 0.8em;
        @include font-lato(bold);
        padding: 0.4em 0;
      }
    }
  }

  .logo {
    margin-top: 0.6em;
    max-height: 60px;
    height: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: $gutter0;
    img {
      display: block;
      height: 100%;
      &[src*='tagline'] {
        margin-top: 0.4em;
      }
    }
  }

  svg.uk-flag {
    height: 0.8em;
    width: auto;
    margin-right: $gap0;
  }

  ul {
    display: grid;
    grid-gap: $gap;
    margin-top: $gutter;
    grid-template-columns: repeat(5, 1fr);
    @include break-in(1500) {
      grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    }
    li {
      @include font-lato(bold);
      display: flex;
      position: relative;
      flex-direction: column;
      place-items: center;
      border-radius: 5px;
      color: white;
      padding: $gap0;
      border: 1px solid $grey2;
      max-width: 100px;
      cursor: pointer;
      user-select: none;
      transition: color $transition;
      &::before {
        content: attr(data-index);
        color: $red;
        margin-bottom: -0.5em;
        display: flex;
      }

      &,
      &::before {
        transition: color $transition;
        transition-delay: 0.2s;
      }

      abbr {
        text-decoration: none;
        font-size: 2em;
        display: block;
      }

      p {
        position: absolute;
        padding: inherit;
        background-color: $grey2;
        font-size: 0.8em;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        opacity: 0;
        transition: opacity $transition;
      }
    }
  }

  .cta {
    margin-top: $gutter;
    a:not(.button) {
      color: $red;
      margin-left: $gutter0;
      @include hover {
        color: white;
      }
    }
  }

  @include break-out(max) {
    .promo {
      padding-left: calc(var(--computed-gutter) + #{$gutter3});
    }
  }

  &.esg-promo {
    overflow: visible;
    tag-line {
      span {
        color: $primary;
      }
    }

    .text {
      color: $white;
    }
  }
}
