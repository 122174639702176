#hero .promo {
  grid-row: 1/-1;
  height: 100%;
  z-index: 1;
  position: relative;
  text-align: center;
  padding: 0;
  overflow-x: clip;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#hero .promo .content {
  padding: var(--spacer1) var(--gap);
}
@media (min-width: 901px) {
  #hero .promo .content {
    padding: var(--gap);
  }
}
#hero .promo .content > svg {
  height: 60px;
  width: 60px;
  margin: 0 auto var(--gap);
  color: #DF3035;
}
#hero .promo:last-of-type {
  grid-column: 1;
  grid-row: 2;
  overflow: visible;
}
#hero .promo:last-of-type picture,
#hero .promo:last-of-type background-video {
  left: 0;
}
#hero .promo picture,
#hero .promo background-video {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  min-width: 50vw;
}
#hero .promo picture video,
#hero .promo picture img,
#hero .promo background-video video,
#hero .promo background-video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 769px) {
  #hero .promo {
    grid-row: 1;
    grid-column: 1;
    overflow-x: visible;
    min-height: 550px;
  }
  #hero .promo .text h2 {
    font-size: 48px;
    padding: 0 var(--gap);
  }
  #hero .promo.right {
    grid-column: 2;
    grid-row: 1;
  }
}
@media (min-width: 1201px) {
  #hero .promo .content {
    margin: 0 auto;
    padding: var(--spacer1);
    max-width: 550px;
  }
}
#hero .promo picture:before {
  content: "";
  top: 0;
  right: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
}
#hero .promo.blue picture:before {
  background-color: rgba(41, 43, 104, 0.8);
}
#hero .promo.black picture:before {
  background-color: rgba(0, 0, 0, 0.6);
}
#hero .promo.red picture:before {
  background-color: rgba(223, 48, 53, 0.8);
}
#hero .promo.none header span {
  background-color: #76bb28;
}
#hero .promo tag-line {
  letter-spacing: 3px;
  font-size: 30px;
  flex-wrap: wrap;
}
#hero .promo tag-line span img {
  margin-right: var(--gap);
}
@media (min-width: 1121px) {
  #hero .promo tag-line {
    font-size: 24px;
  }
  #hero .promo tag-line span img {
    margin-right: calc(var(--gap) * 0.5);
    height: 24px;
    width: 24px;
  }
}
@media (min-width: 1501px) {
  #hero .promo tag-line {
    font-size: 30px;
  }
  #hero .promo tag-line span img {
    margin-right: var(--gap);
    height: 35px;
    width: 35px;
  }
}
#hero .promo header {
  --promo-banner-scale: 0.7;
  position: absolute;
  top: 0;
  right: -250px;
  transform: translate(-40px, 40px) rotate(45deg) scale(var(--promo-banner-scale, 1));
  transform-origin: 50% 0%;
  width: 500px;
}
#hero .promo header h2 {
  text-align: center;
  color: white;
  text-transform: uppercase;
}
#hero .promo header h2 span {
  display: flex;
  flex-direction: column;
  background-color: #292B68;
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.5em;
  padding: 0.4em 0;
}
#hero .promo header h2 span strong {
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#hero .promo header h2 em {
  display: block;
  font-style: normal;
  background-color: #DF3035;
  font-size: 0.8em;
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0.4em 0;
}
#hero .promo .logo {
  margin-top: 0.6em;
  max-height: 60px;
  height: 100%;
  display: inline-block;
  position: relative;
  margin-bottom: var(--gutter0);
}
#hero .promo .logo img {
  display: block;
  height: 100%;
}
#hero .promo .logo img[src*=tagline] {
  margin-top: 0.4em;
}
#hero .promo svg.uk-flag {
  height: 0.8em;
  width: auto;
  margin-right: calc(var(--gap) * 0.5);
}
#hero .promo ul {
  display: grid;
  grid-gap: var(--gap);
  margin-top: var(--gutter1);
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 1500px) {
  #hero .promo ul {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  }
}
#hero .promo ul li {
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  position: relative;
  flex-direction: column;
  place-items: center;
  border-radius: 5px;
  color: white;
  padding: calc(var(--gap) * 0.5);
  border: 1px solid #6A6A6A;
  max-width: 100px;
  cursor: pointer;
  user-select: none;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
#hero .promo ul li::before {
  content: attr(data-index);
  color: #DF3035;
  margin-bottom: -0.5em;
  display: flex;
}
#hero .promo ul li, #hero .promo ul li::before {
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  transition-delay: 0.2s;
}
#hero .promo ul li abbr {
  text-decoration: none;
  font-size: 2em;
  display: block;
}
#hero .promo ul li p {
  position: absolute;
  padding: inherit;
  background-color: #6A6A6A;
  font-size: 0.8em;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  opacity: 0;
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
#hero .promo .cta {
  margin-top: var(--gutter1);
}
#hero .promo .cta a:not(.button) {
  color: #DF3035;
  margin-left: var(--gutter0);
}
@media (hover: hover) {
  #hero .promo .cta a:not(.button):hover {
    color: white;
  }
}
@media (min-width: 1681px) {
  #hero .promo .promo {
    padding-left: calc(var(--computed-gutter) + var(--gutter3));
  }
}
#hero .promo.esg-promo {
  overflow: visible;
}
#hero .promo.esg-promo tag-line span {
  color: var(--primary, #131f6b);
}
#hero .promo.esg-promo .text {
  color: #FFFFFF;
}